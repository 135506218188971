import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo";
import SimpleBlock from "../components/simpleBlock/simpleBlock";

const Privacy = ()=>{
  return (
    <Layout isBg>
      <Seo
        lang={'en'}
        title="Privacy policy | AI solutions"
        description={"The following is aisolutions.kz online privacy policy that helps users understand their obligations and rights and improve site visitors experience due to cookie policy."}
      />
      <SimpleBlock>
        <>
          <h1>Privacy policy</h1>
          <p>
            This Privacy Policy (called “Policy” from now on) is for informational purposes only and is entitled to help users understand their obligations and rights. This Policy is valid from January 1st, 2019 The personal data administrator is aisolutions.kz (from now on called “Administrator”) with headquarters in Republic of Kazakhstan, Almaty, Bostandyk district, st. Gagarina 124 +7(777)076-64-96, mail@aisolutions.kz
          </p><p>
            The administrator makes all the necessary efforts to protect the interests of individuals and organizations sharing their personal and corporate information. Moreover, it ensures that the data is gathered and stored, and will serve only the specific purposes for which a specific consent has been provided. The administrator understands the importance of the privacy levels demanded from the site visitors and users in current and potential cooperation agreements, as well as customers, sharing personal data making use of the following:
          </p><p>
            The official website aisolutions.kz and all its subpages.
          </p><p>
            Social platforms:

            facebook.com, including all subdomains under the “Facebook” brand, regulated by its own policies: facebook.com/legal/terms
            linkedin.com, including all subdomains under the “LinkedIn” brand, regulated by its own policies: linkedin.com/legal/user-agreement
            instagram.com, including all subdomains under the “Instagram” brand, regulated by its own policies: help.instagram.com/478745558852511
            twitter.com, including all subdomains under the “Twitter” brand, regulated by its own policies: twitter.com/en/tos
          </p><p>
            Your privacy is important to aisolutions.kz. To better protect your privacy we provide this notice explaining our online information practices & the choices you can make about the way your information is collected and used. This notice applies to all information collected or submitted on the aisolutions.kz website. You grant us, our affiliated companies and third parties that carry out procedures and provide identification or verification services, permission for the following operations with your Personal Data collected by us.
          </p><p>
            Information Collection
          </p><p>
            We collect the following personal information from you:
          </p><p>
            Contact Information such as name & email address
            Information about your business such as company name
            Demographic information such as zip code
            Information Use
            We use this information to:

            Assess the needs of your business to determine suitable products Respond to customer service requests
            Send you marketing communications
            Respond to your questions and concerns
            Improve our Web site and marketing efforts
            Conduct research and analysis
            Service providers
          </p><p>
            We use other third parties such as an email service provider to send out emails on our behalf, and a web-hosting provider to host our services. When you sign up for our services, we will share your personal information only as necessary for the third party to provide that service.
          </p><p>
            Information Sharing
          </p><p>
            We will share your personal information with third parties only in the ways that are described in this privacy statement.
          </p><p>
            Security
          </p><p>
            The security of your personal information is important to us. When you enter sensitive information (e-mail) on our message forms, we encrypt the transmission of information using secure socket layer technology (SSL).
            We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. If you have any questions about security on our website, you can contact us at mail@aisolutions.kz
          </p><p>
            The use of cookies and web beacons
          </p><p>
            We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. Cookies help us make our Site and your experience better.
          </p><p>
            We may log information using digital images called web beacons on our Site or in our emails.
          </p><p>
            This information is used to make our Site work more efficiently, as well as to provide business and marketing information to the owners of the Site, and to gather such personal data as browser type and operating system, referring page, path through site, domain of ISP, etc. for the purposes of understanding how visitors use our Site. Cookies and similar technologies help us tailor our Site to your personal needs, as well as to detect and prevent security threats and abuse. If used alone, cookies and web beacons do not personally identify you.
          </p><p>
            How long we keep your data
          </p><p>
            We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.
          </p><p>
            This means that we may retain your personal data for a reasonable period after your last interaction with us. When the personal data that we have collected is no longer required, we will delete it securely. We may process data for statistical purposes, but in such cases, data will be anonymized.
          </p><p>
            Changes To This Privacy Policy
          </p><p>
            The Administrator reserves the right to perform any changes in the Policy, which can be necessary (but not limited) due to legal changes, having impact in our obligations and/or customers and users rights. From time to time, this policy may change. Please check back periodically for any updates or changes to this page.
          </p><p>
            Data Storage and Retention Policy
          </p><p>
            Your Personally Identifiable Information is stored in our databases on servers of cloud-based data management services with which aisolutions.kz engages.
          </p><p>
            The current Policy should be treated as a general reference document, for general information purposes only. It is not a legal document or a contract. In case any doubts, concerns or questions emerge after reading it, please contact the Administrator.
          </p><p>
            Miscellaneous
          </p><p>
            By submitting a request for consultation or ordering services, you agree for our usage of your data. We use data from your request in order to contact you, offer services or help to resolve an issue. You can leave data for communication purposes. Then we will discuss the details by phone, email or in person. The current Policy should be treated as a general reference document, for general information purposes only. It is not a legal document or a contract. In case any doubts, concerns or questions emerge after reading it, please contact the Administrator.
          </p><p>
            Contact Us

            If you have any questions about this Privacy Policy, Personal Information use or the Site please contact us at:
            aisolutions.kz
            st. Gagarina 124,
            Bostandyk district, Almaty,
            Republic of Kazakhstan
            Contact: +7(777)076-64-96
            Email: mail@aisolutions.kz
            Web: aisolutions.kz

          </p>
        </>
      </SimpleBlock>

    </Layout>
  )
}
export default Privacy